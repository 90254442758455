<template>
    <div>
        <b-card class="p-1 p-lg-0">
            <div class="heading-status">
                <ul class="list-inline mb-0">
                    <b-col lg="3" md="12" sm="12">
                        <b-form-group>
                            <div v-if="requirement.id">
                                <h3>
                                    <b-badge
                                        :variant="requirementsStatus.find(element => element.id == requirement.status) ? requirementsStatus.find(element => element.id == requirement.status).class : ''">
                                        {{
                                            requirementsStatus.find(element => element.id == requirement.status) ? requirementsStatus.find(element => element.id == requirement.status).name : ''
                                        }}
                                    </b-badge>
                                </h3>
                            </div>
                            <div v-else>
                                <h3>
                                    <b-badge variant="primary">
                                        {{
                                            requirementsStatus.find(element => element.id == 1) ? requirementsStatus.find(element => element.id == 1).name : ''
                                        }}
                                    </b-badge>
                                </h3>
                            </div>

                            <small v-if="validation_requirement.status" class="text-danger">
                                {{ validation_requirement.status }}
                            </small>
                        </b-form-group>
                    </b-col>
                </ul>
            </div>

            <b-tabs align="left">
                <b-tab active title="Informació general" lazy>
                    <b-overlay
                        variant="white"
                        :show="showLoading"
                        spinner-variant="primary"
                        blur="0"
                        opacity=".75"
                        rounded="sm"
                    >
                        <b-row>
                            <b-col sm="6">
                                <b-form-group label="Client" label-for="value">
                                    <v-select
                                        v-model="requirement.client_id"
                                        :options="clients"
                                        label="name"
                                        :reduce="(option) => option.id"
                                        :disabled="this.edit"
                                    >
                                        <template slot="option" slot-scope="option">
                                            {{ option.name }} {{ option.first_surname }} {{ option.second_surname }}
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            {{ option.name }} {{ option.first_surname }} {{ option.second_surname }}
                                        </template>
                                        <template #no-options="{ }">
                                            No s'han pogut carregar els clients
                                        </template>
                                    </v-select>
                                    <small v-if="validation_requirement.client_id" class="text-danger">
                                        {{ validation_requirement.client_id }}
                                    </small>

                                </b-form-group>
                            </b-col>
                            <b-col lg="2" md="2" sm="2">
                                <b-form-group label="Asociar requeriment anterior" label-for="invoice">
                                    <v-select
                                        v-model="requirement.has_requirement_reference"
                                        :options="boolean"
                                        :reduce="(option) => option.value"
                                        :disabled="this.edit"
                                        label="name"
                                    />
                                    <small v-if="validation_requirement.has_requirement_reference" class="text-danger">
                                        {{ validation_requirement.has_requirement_reference }}
                                    </small>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4" md="12" sm="12">
                                <b-form-group label="Tipus de requeriment" label-for="value">
                                    <v-select
                                        v-model="requirement.taxes_types"
                                        multiple
                                        :reduce="(option) => option.id"
                                        :options="taxesTypes"
                                        label="name"
                                        :disabled="readOnly"
                                    >
                                        <template #no-options="{ }">
                                            No s'han pogut carregar els clients
                                        </template>
                                        <template v-slot:selected-option="option">
                                            <div :class="readOnly ? 'selected-option-disabled' : ''"
                                                 v-html="option.name"></div>
                                        </template>
                                    </v-select>
                                    <small v-if="validation_requirement.taxes_types" class="text-danger">
                                        {{ validation_requirement.taxes_types }}
                                    </small>

                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-modal ref="modalRequirementsRelated" id="bv-modal-example" centered no-close-on-backdrop
                                 size="lg">
                            <template #modal-title>
                                Requeriments anteriors
                            </template>
                            <b-container fluid>
                                <b-row class="mb-1">
                                    <div v-if="requirementReferenceOptions.length > 0" class="modal-body">
                                        <table class="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Data recepció client</th>
                                                <th scope="col">Data envio al·legació</th>
                                                <th scope="col">Descripció</th>
                                                <th scope="col">Veure</th>
                                            </tr>
                                            </thead>
                                            <tbody class="table justify-content-center">
                                            <tr
                                                v-for="(requirement_reference, index) in requirementReferenceOptions"
                                                :key="index" class="">
                                                <td>
                                                    <b-form-group>
                                                        <input type="radio" v-model="checkBoxReference" :key="index"
                                                               name="radio" :value="1"
                                                               @change="setRequirementReference(requirement_reference.id)"/>
                                                    </b-form-group>
                                                </td>
                                                <td>
                                                    {{
                                                        helpers.formatDateString(requirement_reference.date_received_client)
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                        helpers.formatDateString(requirement_reference.date_sent_allegation)
                                                    }}
                                                </td>
                                                <td>{{ requirement_reference.description }}</td>
                                                <td class="text-center">
                                                    <router-link
                                                        :to="{ name: 'requirements_edit', params: { id: requirement_reference.id} }"
                                                        target="_blank">
                                                        <feather-icon
                                                            icon="EyeIcon"
                                                            class="cursor-pointer"
                                                            size="16"
                                                        />
                                                    </router-link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div v-else>
                                        <span>Aquest client no té cap al·legació rebutjada.</span>
                                    </div>
                                </b-row>
                            </b-container>

                            <template #modal-footer="{ ok, cancel, hide }">
                                <b-button v-if="checkBoxReference" size="sm" variant="success"
                                          @click="addRequirementReference(true)">
                                    Afegir requeriment relacionat
                                </b-button>
                                <b-button size="sm" variant="outline-secondary" @click="hideModal">
                                    Cancel·lar
                                </b-button>
                            </template>

                        </b-modal>
                        <b-row>
                            <b-col lg="3" md="12" sm="12">
                                <b-form-group
                                    label-for="date-effect"
                                    label="Data recepció client"
                                >
                                    <flat-pickr
                                        v-model="requirement.date_received_client"
                                        class="form-control"
                                        :class="validation_requirement.date_received_client ? 'is-invalid': ''"
                                        :disabled="readOnly"
                                        name="date"
                                        placeholder="Data recepció client"
                                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                                    />
                                    <small v-if="validation_requirement.date_received_client" class="text-danger">
                                        {{ validation_requirement.date_received_client }}</small>
                                </b-form-group>
                            </b-col>

                            <b-col lg="3" md="12" sm="12">
                                <b-form-group
                                    label-for="date-effect"
                                    label="Data venciment"
                                >
                                    <flat-pickr
                                        v-model="requirement.date_expiration"
                                        class="form-control"
                                        :disabled="readOnly"
                                        :class="validation_requirement.date_expiration ? 'is-invalid': ''"
                                        name="date"
                                        placeholder="Data venciment"
                                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                                    />
                                    <small v-if="validation_requirement.date_expiration" class="text-danger">
                                        {{ validation_requirement.date_expiration }}</small>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" md="12" sm="12">
                                <b-form-group
                                    label-for="added_days"
                                    label="Ampliació termini"
                                >
                                    <div class="demo-inline-spacing mb-0">
                                        <b-form-radio v-model="requirement.added_days" :disabled="readOnly"
                                                      name="added_days"
                                                      value="1">
                                            Sì
                                        </b-form-radio>
                                        <b-form-radio v-model="requirement.added_days" :disabled="readOnly"
                                                      name="added_days"
                                                      value="0">
                                            No
                                        </b-form-radio>
                                    </div>
                                    <small v-if="validation_requirement.added_days" class="text-danger">
                                        {{ validation_requirement.added_days }}</small>
                                </b-form-group>
                            </b-col>
                            <b-col v-if="requirement.added_days == 1" lg="3" md="12" sm="12">
                                <b-form-group
                                    label-for="date-effect"
                                    label="Data venciment amb ampliació"
                                >
                                    <flat-pickr
                                        v-model="requirement.date_expiration_added_days"
                                        class="form-control"
                                        :disabled="readOnly"
                                        :class="validation_requirement.date_expiration_added_days ? 'is-invalid': ''"
                                        name="date"
                                        placeholder="Data venciment amb ampliació"
                                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                                    />
                                    <small v-if="validation_requirement.date_expiration_added_days" class="text-danger">
                                        {{ validation_requirement.date_expiration_added_days }}</small>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col v-if="requirement.added_days == 1" lg="8" md="12" sm="12">
                                <b-form-group label="Document solicitut ampliació" label-for="date-document">
                                    <b-form-file
                                        v-model="requirement.enlargement_document_file"
                                        :disabled="readOnly"
                                        placeholder="Tria un document o deixa'l anar aquí..."
                                        drop-placeholder="Tria un document o deixa'l anar aquí..."
                                        browse-text="Seleccionar"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col v-if="requirement.enlargement_document" lg="1" md="12" sm="12">
                                <a :href="baseUrl+requirement.enlargement_document" target="_blank">
                                    <b-button
                                        variant="outline-success"
                                        class="mt-0 mt-md-2"
                                    >
                                        <feather-icon
                                            icon="EyeIcon"
                                            class="mr-25"

                                        />
                                    </b-button>
                                </a>
                            </b-col>
                            <b-col v-if="requirement.enlargement_document" lg="1" md="12" sm="12" class="mt-2">
                                <b-button
                                    variant="outline-danger"
                                    :disabled="readOnly"
                                    @click="requirement.enlargement_document_file = requirement.enlargement_document = null "
                                >
                                    <feather-icon
                                        icon="TrashIcon"
                                        class="mr-25"
                                    />
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col v-if="!(requirement.taxes_types.includes(3) && requirement.taxes_types.length ==1)"
                                   lg="3" md="12" sm="12">
                                <b-form-group label="Periode" label-for="Periode">
                                    <v-select
                                        v-model="requirement.trimester"
                                        label="name"
                                        :disabled="readOnly"
                                        :reduce="(option) => option.value"
                                        :options="trimesters"
                                    >
                                        <template #no-options="{ }">
                                            No s'han pogut carregar els periodes
                                        </template>
                                    </v-select>
                                    <small v-if="validation_requirement.trimester" class="text-danger"> {{
                                            validation_requirement.trimester
                                        }}</small>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4" md="12" sm="12" class="text-center">
                                <b-form-group label="Any" label-for="risk">
                                    <b-row>
                                        <b-col>
                                            <v-select
                                                v-model="requirement.init_year"
                                                :disabled="readOnly"
                                                :options="years"
                                                label="name"
                                            />
                                        </b-col>
                                        <small v-if="validation_requirement.init_year" class="text-danger"> {{
                                                validation_requirement.init_year
                                            }}</small>
                                        <b-col>
                                            <v-select
                                                v-model="requirement.end_year"
                                                :disabled="readOnly"
                                                :options="years"
                                                label="name"
                                            />
                                        </b-col>
                                        <small v-if="validation_requirement.end_year" class="text-danger"> {{
                                                validation_requirement.end_year
                                            }}</small>
                                    </b-row>

                                </b-form-group>
                            </b-col>
                            <b-col lg="3" md="12" sm="12">
                                <b-form-group label="Persona encarregada" label-for="person">
                                    <v-select
                                        v-model="requirement.person_in_charge"
                                        :options="users_department"
                                        :reduce="(option) => option.id"
                                        label="name"
                                        :disabled="readOnly"
                                    />
                                    <small v-if="validation_requirement.person_in_charge" class="text-danger"> {{
                                            validation_requirement.person_in_charge
                                        }}</small>
                                </b-form-group>
                            </b-col>
                            <b-col lg="2" md="12" sm="12">
                                <b-form-group label="Es necessitarà factura" label-for="invoice">
                                    <v-select
                                        v-model="requirement.invoice"
                                        :options="boolean"
                                        :reduce="(option) => option.value"
                                        label="name"
                                        :disabled="readOnly"
                                    />
                                    <small v-if="validation_requirement.invoice" class="text-danger"> {{
                                            validation_requirement.invoice
                                        }}</small>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col lg="12" md="12" sm="12">
                                <b-form-group label="Informació interna" label-for="risk">
                                    <b-form-textarea v-model="requirement.description" placeholder="Descripció"
                                                     :disabled="readOnly"
                                                     :class="validation_requirement.description ? 'is-invalid': ''"/>
                                    <small v-if="validation_requirement.description" class="text-danger">
                                        {{ validation_requirement.description }}
                                    </small>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <!-- Formulario de Socios -->
                        <form-partners v-if="this.clientData && this.clientData.type === 'legal_person'"/>

                        <!-- Formulario de Epigrafes -->
                        <form-epigraphs :readOnly="readOnly"/>

                        <!-- Formulario de Impuestos -->
                        <form-taxes :readOnly="readOnly"/>

                        <b-card title="Autorització" border-variant="primary" class="text-left">
                            <b-row class="col-sm-12  mt-1">
                                <b-col lg="10" md="12" sm="12">
                                    <b-form-group label="Document autorització client" label-for="date-document">
                                        <b-form-file
                                            v-model="requirement.authorization_document_file"
                                            :disabled="readOnly"
                                            placeholder="Tria un document o deixa'l anar aquí..."
                                            drop-placeholder="Tria un document o deixa'l anar aquí..."
                                            browse-text="Seleccionar"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col v-if="requirement.authorization_document" lg="1" md="12" sm="12">
                                    <a :href="baseUrl+requirement.authorization_document" target="_blank">
                                        <b-button
                                            variant="outline-success"
                                            class="mt-0 mt-md-2"
                                        >
                                            <feather-icon
                                                icon="EyeIcon"
                                                class="mr-25"

                                            />
                                        </b-button>
                                    </a>
                                </b-col>
                                <b-col v-if="requirement.authorization_document" lg="1" md="12" sm="12" class="mt-2">
                                    <b-button
                                        variant="outline-danger"
                                        :disabled="readOnly"
                                        @click="requirement.authorization_document_file = requirement.authorization_document = null "
                                    >
                                        <feather-icon
                                            icon="TrashIcon"
                                            class="mr-25"
                                        />
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card
                            v-if="requirement.date_finalization" border-variant="primary" class="text-left">
                            <b-col>
                                <span>Requeriment finalitzat dia {{ requirement.date_finalization }}</span>
                            </b-col>
                        </b-card>
                        <b-card
                            v-if="requirement.requirement_reference && requirement.requirement_reference.id"
                            title="Requeriment de referència" border-variant="primary" class="text-left">
                            <b-col>
                                <table class="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th scope="col">Data recepció client</th>
                                        <th scope="col">Data envio al·legació</th>
                                        <th scope="col">Descripció</th>
                                        <th scope="col">Accions</th>
                                    </tr>
                                    </thead>
                                    <tbody class="table justify-content-center">
                                    <tr>
                                        <td>
                                            {{
                                                helpers.formatDateString(requirement.requirement_reference.date_received_client)
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                helpers.formatDateString(requirement.requirement_reference.date_sent_allegation)
                                            }}
                                        </td>
                                        <td>{{ requirement.requirement_reference.description }}</td>
                                        <td>
                                            <b-row class="text-center">
                                                <b-col sm="6">
                                                    <router-link
                                                        :to="{ name: 'requirements_edit', params: { id: requirement.requirement_reference.id} }"
                                                        target="_blank">
                                                        <feather-icon
                                                            :id="`see-reference`"
                                                            icon="EyeIcon"
                                                            class="cursor-pointer"
                                                            size="16"
                                                        />
                                                    </router-link>
                                                    <b-tooltip
                                                        title="Veure referència"
                                                        :target="`see-reference`"
                                                    />
                                                </b-col>
                                                <b-col sm="6">
                                                    <feather-icon
                                                        @click="requirement.requirement_reference = null;requirement.has_requirement_reference = 0;requirement.requirement_reference_id = null"
                                                        icon="TrashIcon" :id="`invoice-row-preview-icon`"
                                                        class="cursor-pointer"
                                                        size="16"
                                                    />
                                                    <b-tooltip
                                                        title="Desfer referència"
                                                        :target="`invoice-row-preview-icon`"
                                                    />
                                                </b-col>
                                            </b-row>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </b-col>
                        </b-card>
                        <b-row>
                            <b-col>
                                <b-button v-if="!edit" variant="primary" class="mt-2 mr-1" @click="sendData">
                                    Desar canvis
                                </b-button>
                                <b-button v-else variant="primary" class="mt-2 mr-1" @click="saveData"
                                          :disabled="readOnly">
                                    Actualitzar canvis
                                </b-button>
                                <b-button variant="outline-secondary" type="reset" class="mt-2" @click="cancel"
                                          :disabled="readOnly">
                                    Cancel·lar
                                </b-button>
                            </b-col>
                            <b-col class="d-flex justify-content-end">
                                <b-button :disabled="readOnly"
                                          v-if="requirement.status === this.getPhaseId('preparation') && requirement.id"
                                          variant="primary" class="mt-2" @click="saveData('2')">
                                    Fi preparació
                                </b-button>
                                <b-button :disabled="readOnly"
                                          v-if="!requirement.date_finalization && (requirement.status === this.getPhaseId('solved_accepted') || requirement.status === this.getPhaseId('solved_rejected'))"
                                          variant="primary" class="mt-2 mr-1" @click="finishAllegation()">
                                    Finalització d'al·legació
                                </b-button>
                                <b-button :disabled="readOnly"
                                          v-if="requirement.status === this.getPhaseId('waiting_resolution')"
                                          variant="danger" class="mt-2 mr-1" @click="saveData('7')">
                                    Al·legació rebutjada
                                </b-button>
                                <b-button :disabled="readOnly"
                                          v-if="requirement.status === this.getPhaseId('waiting_resolution')"
                                          variant="success" class="mt-2 mr-1" @click="saveData('6')">
                                    Al·legació acceptada
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </b-tab>
                <b-tab v-if="requirement.id" title="Dades personals" lazy>
                    <form-client-personal-data/>
                </b-tab>
                <b-tab v-if="requirement.id > 0 && requirement.status !== this.getPhaseId('preparation')"
                       title="Al·legació i Revisió" lazy>
                    <b-overlay
                        variant="white"
                        :show="showLoading"
                        spinner-variant="primary"
                        blur="0"
                        opacity=".75"
                        rounded="sm"
                    >
                        <b-row>
                            <b-col lg="12" md="12" sm="12">
                                <b-form-group label="Informació interna" label-for="risk">
                                    <b-form-textarea v-model="requirement.description" placeholder="Informació interna"
                                                     :disabled="readOnly"
                                                     :class="validation_requirement.description ? 'is-invalid': ''"/>
                                    <small v-if="validation_requirement.description" class="text-danger">
                                        {{ validation_requirement.description }}
                                    </small>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-for="(requirement_allegation, index) in requirement.allegations"
                             :key="index">
                            <div>
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button block href="#" v-b-toggle="'accordion-' + index"
                                              :variant="requirement_allegation.active == 1 ? 'primary' : 'secondary'">
                                        Al·legació
                                        {{ requirement_allegation.active == 1 ? '' : requirement_allegation.order + 1 }}
                                    </b-button>
                                </b-card-header>
                                <b-collapse :id="'accordion-' + index" class="mt-2"
                                            :visible="requirement_allegation.active == 1">
                                    <b-card border-variant="secondary" disabled="true"
                                            class="mb-1 mt-2">
                                        <b-col class="col-sm-12">
                                            <b-row v-if="requirement_allegation.created_at" class="justify-content-end">
                                                <span
                                                    class="text-right">Data: {{
                                                        helpers.formatDateHour(requirement_allegation.created_at)
                                                    }}</span>
                                            </b-row>

                                            <b-form-group label="Text" label-for="value">
                                                <b-form-textarea
                                                    :disabled="requirement_allegation.active == 0"
                                                    rows="5"
                                                    v-model="requirement_allegation.allegation"/>
                                            </b-form-group>
                                        </b-col>
                                        <b-row class="col-lg-12">
                                            <b-col lg="6" md="12" sm="12">
                                                <b-form-group label="Document al·legació" label-for="date-document">
                                                    <b-form-file
                                                        v-model="requirement_allegation.allegation_document_file"
                                                        placeholder="Tria un document o deixa'l anar aquí..."
                                                        drop-placeholder="Tria un document o deixa'l anar aquí..."
                                                        browse-text="Seleccionar"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col v-if="requirement_allegation.allegation_document" lg="1" md="12"
                                                   sm="12">
                                                <a :href="baseUrl+requirement_allegation.allegation_document"
                                                   target="_blank">
                                                    <b-button
                                                        variant="outline-success"
                                                        class="mt-0 mt-md-2"
                                                    >
                                                        <feather-icon
                                                            icon="EyeIcon"
                                                            class="mr-25"
                                                        />
                                                    </b-button>
                                                </a>
                                            </b-col>
                                            <b-col v-if="requirement_allegation.allegation_document" lg="2" md="12"
                                                   sm="12"
                                                   class="mt-2">
                                                <b-button
                                                    variant="outline-danger"
                                                    @click="deleteDocument(index, requirement_allegation.id,'allegation_document')"
                                                >
                                                    <feather-icon
                                                        icon="TrashIcon"
                                                        class="mr-25"
                                                    />
                                                </b-button>
                                            </b-col>
                                            <b-col lg="6" md="12" sm="12">
                                                <b-form-group label="Comprovant justificant enviament de al·legació"
                                                              label-for="date-document">
                                                    <b-form-file
                                                        v-model="requirement_allegation.allegation_sent_document_file"
                                                        placeholder="Tria un document o deixa'l anar aquí..."
                                                        drop-placeholder="Tria un document o deixa'l anar aquí..."
                                                        browse-text="Seleccionar"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col v-if="requirement_allegation.allegation_sent_document" lg="1" md="12"
                                                   sm="12">
                                                <a :href="baseUrl+requirement_allegation.allegation_sent_document"
                                                   target="_blank">
                                                    <b-button
                                                        variant="outline-success"
                                                        class="mt-0 mt-md-2"
                                                    >
                                                        <feather-icon
                                                            icon="EyeIcon"
                                                            class="mr-25"
                                                        />
                                                    </b-button>
                                                </a>
                                            </b-col>
                                            <b-col v-if="requirement_allegation.allegation_sent_document" lg="2" md="12"
                                                   sm="12"
                                                   class="mt-2">
                                                <b-button
                                                    variant="outline-danger"
                                                    @click="deleteDocument(index, requirement_allegation.id, 'allegation_sent_document')"
                                                >
                                                    <feather-icon
                                                        icon="TrashIcon"
                                                        class="mr-25"
                                                    />
                                                </b-button>
                                            </b-col>
                                        </b-row>

                                        <b-col sm="12">
                                            <b-form-group label="Revisió" label-for="value">
                                                <b-form-textarea
                                                    :disabled="requirement_allegation.active == 0"
                                                    rows="5"
                                                    v-model="requirement_allegation.review"/>
                                            </b-form-group>
                                        </b-col>
                                    </b-card>
                                </b-collapse>
                            </div>
                            <b-row v-if="requirement_allegation.active == 1">
                                <b-col>
                                    <b-button v-if="!edit" variant="primary" class="mt-2 mr-1" @click="sendData">
                                        Desar canvis
                                    </b-button>
                                    <b-button
                                        v-else-if="edit && requirement.allegations.length > 0 && (requirement.status === getPhaseId('allegation') || requirement.status === getPhaseId('modifying_allegation')) "
                                        variant="primary"
                                        class="mt-2 mr-1" @click="saveData()">
                                        Actualitzar canvis
                                    </b-button>
                                </b-col>
                                <b-col class="d-flex justify-content-end">
                                    <b-button
                                        v-if="(requirement.status === getPhaseId('allegation') || requirement.status === getPhaseId('modifying_allegation'))  && requirement.allegations.length > 0"
                                        variant="primary" class="mt-2 mr-1" @click="saveData('3')">
                                        Enviar per revisió
                                    </b-button>
                                    <b-button
                                        v-if="requirement.status === getPhaseId('review')"
                                        variant="primary" class="mt-2 mr-1" @click="saveData('4')">
                                        Aprovar al·legació
                                    </b-button>
                                    <b-button
                                        v-if="requirement.status === getPhaseId('review')"
                                        variant="danger" class="mt-2 mr-1" @click="setRedoAllegation();">
                                        Modificar al·legació
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </b-overlay>
                </b-tab>
                <b-tab v-if="requirement.id" title="Documentació" lazy>
                    <b-card border-variant="secondary" title="Històric" disabled="true">
                        <b-row>
                            <b-row class="col-sm-12"
                                   v-for="(requirement_history, index) in requirement.requirement_historical"
                                   :key="requirement_history.id">
                                <b-col sm="6">
                                    <b-form-group label="Descripció" label-for="description">
                                        <b-form-input v-model="requirement_history.description" name="name"
                                                      autocomplete="off"
                                                      placeholder="Descripció"/>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="1">
                                    <b-form-group label="Hores" label-for="hours">
                                        <b-form-input v-model="requirement_history.hours" autocomplete="off"
                                                      type="number"
                                                      name="first_surname"/>
                                    </b-form-group>
                                </b-col>
                                <b-col v-if="requirement_history.created_at" sm="2">
                                    <b-form-group label="Data" label-for="created_by">
                                        <b-form-input plaintext
                                                      name="date"
                                                      :value="helpers.formatDateHour(requirement_history.created_at)">
                                        </b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="1">
                                    <b-form-group label="Creat per" label-for="created_by">
                                        <b-form-input plaintext
                                                      name="first_surname"
                                                      :value="users.find(option => option.id == requirement_history.user_id).name">
                                        </b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col sm="1">
                                    <b-button
                                        variant="outline-danger"
                                        class="mt-0 mt-md-2"
                                        @click="removeItemHistory(index)"
                                    >
                                        <feather-icon
                                            icon="TrashIcon"
                                            class="mr-25"
                                        />
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-row class="col-sm-12">
                                <b-col class="pb-2">
                                    <b-button variant="" @click="addNewHistory()">
                                        <feather-icon
                                            icon="PlusIcon"
                                            class="mr-25"
                                        />
                                        <span>Afegir</span>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-row>
                    </b-card>

                    <b-card border-variant="secondary" title="Documents" v-if="requirement.id" class="p-1 p-lg-0">
                        <form-documents :readOnly="readOnly"/>
                        <b-row>
                            <b-col>
                                <b-button variant="primary" class="mt-2 mr-1" @click="saveData" :disabled="readOnly">
                                    Guardar canvis
                                </b-button>
                            </b-col>
                            <b-col v-if="requirement.status === this.getPhaseId('waiting_telematic_shipment')"
                                   cols="d-flex justify-content-end">
                                <b-button variant="info" class="mt-2 mr-1" @click="generateZip">
                                    Generar zip de documents
                                </b-button>
                            </b-col>
                            <b-button :disabled="readOnly"
                                      v-if="(requirement.status === this.getPhaseId('waiting_telematic_shipment'))"
                                      variant="primary" class="mt-2 mr-1" @click="saveData('5')">
                                Marcar como enviado telemàticament
                            </b-button>
                        </b-row>
                    </b-card>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import {
    BButton,
    BCard,
    BCol,
    BFormGroup,
    BTab,
    BTabs,
    BOverlay,
    BRow,
    BFormInput,
    BFormTextarea,
    BFormRadio,
    BFormCheckbox,
    BFormFile,
    BBadge,
    BCollapse,
    BCardBody,
    BCardHeader,
    BContainer,
    BTooltip
} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import vSelect from "vue-select"
import {VBToggle} from "bootstrap-vue"
import {REQUIREMENTS_LIST} from "@/router/web/constants"
import flatPickr from "vue-flatpickr-component"
import helpers from "@/resources/helpers/helpers"
import {trimesters, requirementsStatus, epigraphsTypes} from "@/constants/accounting"
import {ID_DEPARTMENT_ACCOUNTING} from "@/constants/departments"
import FormClientPersonalData from "@/views/clients/form/formClientPersonalDataView"
import FormEpigraphs from "@/views/clients/form/components/formEpigraphs"
import FormTaxes from "@/views/clients/form/components/formTaxes"
import FormDocuments from "@/views/accounting/requirements/form/formDocumentsView"
import FormPartners from "@/views/clients/form/components/formPartners"

import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import Swal from "sweetalert2"
import {baseUrl} from "@/constants/app"
import {boolean} from "@/constants/constants"

const {mapFields} = createHelpers({
    getterType: 'requirements/getField',
    mutationType: 'requirements/updateField',
})

export default {
    name: "RequirementFormView",
    components: {
        BCol,
        BRow,
        BButton,
        BFormGroup,
        BCard,
        BOverlay,
        BTab,
        BTabs,
        BFormInput,
        BFormTextarea,
        BFormRadio,
        BFormCheckbox,
        BFormFile,
        BBadge,
        BCollapse,
        BCardBody,
        BCardHeader,
        BContainer,
        BTooltip,

        vSelect,
        VBToggle,
        flatPickr,

        FormClientPersonalData,
        FormDocuments,
        FormPartners,
        FormEpigraphs,
        FormTaxes
    },
    data() {
        return {
            users: [],
            users_department: [],
            clients: [],
            years: [],
            taxesTypes: [],
            epigraphs: [],
            epigraphsSelected: [],
            clientData: null,
            edit: false,
            readOnly: false,
            showLoading: false,
            SpanishLocale,
            daysToAdd: 10,
            checkBoxReference: 0,

            holidays: null,
            isResponsiblePhase: false,
            isResponsiblePhaseReview: false,
            isResponsiblePhaseAllegation: false,
            documentsTypesList: [],
            baseUrl,

            oldRequirementStatus: '',
            requirementReferenceOptions: [],
            canEditAllegation: false,
            visible: false,
            helpers,
            boolean,

            trimesters,
            requirementsStatus,
            epigraphsTypes,
            ID_DEPARTMENT_ACCOUNTING
        }
    },
    computed: {
        ...mapState('requirements', ['validation_requirement']),
        ...mapFields({
            requirement: 'requirement',
            requirement_documents: 'requirement_documents',
            requirement_documents_iva: 'requirement_documents_iva',
            requirement_documents_irpf: 'requirement_documents_irpf',
            requirement_documents_is: 'requirement_documents_is',
            requirement_documents_cens: 'requirement_documents_cens',
            requirement_documents_model_347: 'requirement_documents_model_347',
            requirement_documents_model_180: 'requirement_documents_model_180',
            requirement_documents_model_190: 'requirement_documents_model_190',
            requirement_documents_embargo: 'requirement_documents_embargo',
            requirement_responsibles_phase: 'requirement_responsibles_phase',
            requirement_responsibles_phase_review: 'requirement_responsibles_phase_review',
            requirement_responsibles_phase_allegation: 'requirement_responsibles_phase_allegation',
        }),
    },
    directives: {
        'b-toggle': VBToggle
    },
    watch: {
        'requirement.date_received_client': function (changes) {
            if (changes) {

                if (Object.keys(this.requirement.taxes_types).length === 1) {

                    const checkTaxTypeSancio = obj => (obj.id === this.requirement.taxes_types[0] && obj.name === 'Sanció')

                    if (this.taxesTypes.some(checkTaxTypeSancio)) {
                        this.daysToAdd = 15
                    }
                }

                this.requirement.date_expiration = helpers.formatDate(this.addWorkingDaysToDate(this.requirement.date_received_client, this.daysToAdd))
                this.requirement.date_expiration_added_days = helpers.formatDate(this.addWorkingDaysToDate(this.requirement.date_received_client, 15))
            }
        },
        'requirement.added_days': function (addDays) {
            if (addDays == 1) {
                this.requirement.date_expiration_added_days = helpers.formatDate(this.addWorkingDaysToDate(this.requirement.date_received_client, 15))
            } else {
                this.requirement.date_expiration_added_days = helpers.formatDate(this.addWorkingDaysToDate(this.requirement.date_received_client, 10))
            }
        },
        'requirement.client_id': function (client) {
            if (client) {
                this.getClientData(client)
            }
        },
        'requirement.has_requirement_reference': function (hasReference) {
            if (hasReference) {

                if (this.edit === false) {
                    this.selectRequirementReference(this.requirement.client_id)
                }
            }
        },
        'requirement.requirement_reference_id': function (hasReference) {
            if (hasReference && !this.requirement.id) {
                this.addRequirementReference()
            }
        },
    },
    mounted() {
        this.setDateAndSelects()
        this.getUsers()
    },
    created() {
        this.$store.commit('requirements/RESET_STATE')
        this.$store.commit('client/RESET_STATE')

        this.getTaxesTypes()
        this.getClients()
        this.getHolidays()


        if (this.$route.params.id) {
            this.edit = true
            this.getRequirement()
        } else {
            this.edit = false
        }
        this.$root.$refs.mainFormRequirement = this
    },
    methods: {
        getRequirement() {

            this.showLoading = true

            this.$store.dispatch('requirements/getRequirement', {
                id: this.$route.params.id ?? this.requirement.id,
                relations: ['client', 'client.clientPersonalData', 'client.clientPartners', 'client.clientEpigraphs', 'client.clientFilterData', 'taxesTypes', 'documentsRequirement',
                    'documentsIVA', 'documentsIRPF', 'documentsIS', 'documentsCens', 'documentsModel347', 'documentsModel180', 'documentsModel190',
                    'documentsEmbargo', 'allegations', 'historical', 'requirementReference'],
            })
            .then(response => {

                this.oldRequirementStatus = response.status

                this.getResponsiblePhase(response.status)
                this.requirement_documents_iva = response.documents_i_v_a
                this.requirement_documents_irpf = response.documents_i_r_p_f
                this.requirement_documents_is = response.documents_i_s
                this.requirement_documents_cens = response.documents_cens
                this.requirement_documents_model_347 = response.documents_model347
                this.requirement_documents_model_180 = response.documents_model180
                this.requirement_documents_model_190 = response.documents_model190
                this.requirement_documents_embargo = response.documents_embargo

                this.requirement.person_in_charge = this.users_department.find(option => option.id == response.person_in_charge) ? this.users_department.find(option => option.id == response.person_in_charge).id : null

                if (response.status == '2' && this.requirement.allegations.length === 0) {
                    this.addNewAllegation()
                }

                /*
                if (response.date_finalization) {
                    this.readOnly = true
                }*/

                this.$store.dispatch('notification/listByUser', this.$store.state.AppActiveUser.id)

                this.showLoading = false
            }).catch(error => {
                this.showLoading = false
            })
        },
        getPhaseId(phaseName) {
            return this.requirementsStatus.find(element => element.value === phaseName).id
        },
        getClientData(client_id) {
            this.$root.$emit('component1') //like this
            this.showLoading = true
            this.$store.dispatch('client/getClient', {
                id: client_id,
                relations: ['clientPartners', 'clientEpigraphs', 'clientFilterData'],
            })
            .then(response => {
                this.clientData = this.$store.state.client.client
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        getResponsiblePhase(phaseId) {
            this.showLoading = true
            this.$store.dispatch('requirements/getResponsiblePhase', {
                id: phaseId,
            })
            .then(response => {
                this.isResponsiblePhase = this.requirement_responsibles_phase.includes(this.$store.state.AppActiveUser.id)
                this.isResponsiblePhaseReview = this.requirement_responsibles_phase_review.includes(this.$store.state.AppActiveUser.id)
                this.isResponsiblePhaseAllegation = this.requirement_responsibles_phase_allegation.includes(this.$store.state.AppActiveUser.id)
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        getClients() {
            this.showLoading = true

            this.$store.dispatch('client/getAllClients')
            .then(response => {
                this.clients = response
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
                this.showToast('Error en obtenir clients', 'AlertOctagonIcon', 'danger')
            })
        },
        getHolidays() {
            this.showLoading = true

            this.$store.dispatch('requirements/getAllHolidays')
            .then(response => {
                this.holidays = response
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
                this.showToast('Error en obtenir clients', 'AlertOctagonIcon', 'danger')
            })
        },
        getTaxesTypes() {
            this.showLoading = true

            this.$store.dispatch('requirements/getTaxesTypes')
            .then(response => {
                this.taxesTypes = response
                this.showLoading = false
            })
            .catch(error => {
                console.log(error)
                this.showToast('Error en obtenir tipus d\'impostos', 'AlertOctagonIcon', 'danger')
                this.showLoading = false
            })
        },
        sendData() {
            this.showLoading = true
            this.requirement.client_partners = this.$store.state.client.client.client_partners
            this.requirement.epigraphs = this.$store.state.client.client.client_epigraphs
            this.requirement.client_filter_data = this.$store.state.client.client.client_filter_data
            this.requirement.old_requirement_status = this.oldRequirementStatus

            this.$store.dispatch('requirements/createRequirement', {data: this.formatData()})
            .then(response => {
                this.showLoading = false
                this.requirement.id = response.id
                this.edit = true
                this.getRequirement()

                helpers.showToast('Requeriment creat correctament!', 'CheckIcon', 'success')
            })
            .catch(error => {
                this.showLoading = false
                console.log(error)
                this.showToast('Error en crear el requeriment!', 'AlertOctagonIcon', 'danger')
            })
        },
        setDateAndSelects() {
            let currentYear = new Date().getFullYear()
            let years = []

            for (let i = currentYear; i >= currentYear - 20; i--) {
                years.push(i)
            }

            this.requirement.init_year = currentYear - 1
            this.requirement.end_year = currentYear
            this.years = years
        },
        addWorkingDaysToDate(date_received_client, daysToAdd) {
            if (date_received_client > '') {
                const formated = date_received_client.split("-").reverse().join("-")
                const currentDate = new Date(formated)

                const isHoliday = (date) => this.holidays.includes(date.toISOString().split('T')[0])

                let addedDays = 0

                while (addedDays < daysToAdd) {
                    currentDate.setDate(currentDate.getDate() + 1)

                    // Mira si la fecha es festivo o fin de semana
                    while (isHoliday(currentDate) || currentDate.getDay() === 6 || currentDate.getDay() === 0) {
                        currentDate.setDate(currentDate.getDate() + 1)
                    }

                    addedDays++
                }

                return currentDate.toISOString().split('T')[0]
            }
        },
        addNormalDaysToDate(date_received_client, daysToAdd) {
            if (date_received_client > '') {
                const formated = date_received_client.split("-").reverse().join("-")
                const currentDate = new Date(formated)

                for (let i = 0; i <= daysToAdd; i++) {
                    currentDate.setDate(currentDate.getDate() + 1)
                    if (currentDate.getDay() === 0) {
                        currentDate.setDate(currentDate.getDate() + 1)
                    }
                }
                return currentDate
            }

        },
        saveData(status) {
            this.showLoading = true
            //   this.requirement.person_in_charge = this.person_in_charge.id ?? null
            this.requirement.client_filter_data = this.$store.state.client.client.client_filter_data
            this.requirement.client_partners = this.$store.state.client.client.client_partners
            this.requirement.epigraphs = this.$store.state.client.client.client_epigraphs
            this.requirement.old_requirement_status = this.oldRequirementStatus

            if (typeof status === 'string') {
                this.requirement.status = status
            }

            this.$store.dispatch('requirements/updateRequirement', {data: this.formatData()})
            .then(response => {
                this.getRequirement()
                if (this.requirement.status == 5 && this.requirement.invoice == 1) {
                    this.sendMailInvoice()
                }
                this.showLoading = false
                this.requirement.redo_allegation = 0
                this.showToast('Requeriment actualitzat correctament!', 'CheckIcon', 'success')
            })
            .catch(error => {
                console.log(error)
                this.showToast('Error en editar el requeriment!', 'AlertOctagonIcon', 'danger')
            })

        },
        formatData() {
            var formData = new FormData()

            if (this.has_requirement_reference == 0) {
                this.requirement.requirement_reference_id = null
            }
            if (this.requirement.id) {
                this.appendFormData(formData, this.requirement_documents_model_180, 'requirement_documents_model_180')
                this.appendFormData(formData, this.requirement_documents_model_190, 'requirement_documents_model_190')
                this.appendFormData(formData, this.requirement_documents_embargo, 'requirement_documents_embargo')
                this.appendFormData(formData, this.requirement_documents_model_347, 'requirement_documents_model_347')
                this.appendFormData(formData, this.requirement_documents_cens, 'requirement_documents_cens')
                this.appendFormData(formData, this.requirement_documents_iva, 'requirement_documents_iva')
                this.appendFormData(formData, this.requirement_documents_irpf, 'requirement_documents_irpf')
                this.appendFormData(formData, this.requirement_documents_is, 'requirement_documents_is')
                this.appendFormData(formData, this.requirement_documents, 'requirement_documents')
            }

            this.appendFormData(formData, this.requirement, 'requirement')
            return formData
        },
        appendFormData(formData, data, rootName) {
            let root = rootName || ''
            if (data instanceof File) {
                formData.append(root, data)
            } else if (Array.isArray(data)) {
                for (var i = 0; i < data.length; i++) {
                    this.appendFormData(formData, data[i], root + '[' + i + ']')
                }
            } else if (typeof data === 'object' && data) {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            this.appendFormData(formData, data[key], key)
                        } else {
                            this.appendFormData(formData, data[key], `${root}[${key}]`)
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data)
                } else {
                    formData.append(root, '')
                }
            }
        },
        showToast(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: title,
                    icon: icon,
                    variant: variant,
                },
            })
        },
        cancel() {
            this.$router.push(REQUIREMENTS_LIST)
        },
        generateZip() {
            this.showLoading = true
            this.$store.dispatch('requirements/generateZip', {id: this.requirement.id})
            .then(response => {
                this.showLoading = false
                if (response) {
                    this.downloadZip(response, 'file')
                } else {
                    this.showToast('No hi ha documents per a generar arxiu zip', 'AlertOctagonIcon', 'danger')
                }
            })
            .catch(error => {
                console.log(error)
                this.showLoading = false
            })
        },
        downloadZip(response, filename) {
            const link = document.createElement('a')
            link.href = baseUrl + response
            link.download = `${filename}.zip`
            link.click()
        },
        addNewHistory() {
            this.requirement.requirement_historical.push({
                hours: null,
                comments: null,
                description: null,
                user_id: this.$store.state.AppActiveUser.id,
                requirement_id: this.requirement.id
            })
        },
        addNewAllegation() {
            this.requirement.allegations.push({
                active: 1,
                allegation: null,
                allegation_document_file: null,
                requirement_id: this.requirement.id,
                //   order: 99999,
                review: null,
            })
        },
        removeItem(indexTax, documentId) {
            if (documentId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el document?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('requirements/deleteRequirementDocument', documentId)
                        .then(response => {
                            this.showLoading = false
                            this.showToast('Document eliminado correctament', 'CheckIcon', 'success')
                        })
                        .catch(error => {
                            this.showLoading = false
                            this.showToast('Error en eliminar document', 'CheckIcon', 'Alert')
                            console.log(error)
                        })
                    }
                })
            } else {
                this.requirement_documents.splice(indexTax, 1)
            }

        },
        removeItemHistory(indexTax) {
            this.requirement.requirement_historical.splice(indexTax, 1)
        },
        setRedoAllegation() {
            this.requirement.redo_allegation = 1
            this.addNewAllegation()
            this.saveData('8')
        },
        deleteDocument(index, documentId, type) {
            if (documentId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el document?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('requirements/deleteRequirementDocumentAllegation', {
                            id: documentId,
                            type: type
                        })
                        .then(response => {
                            this.showLoading = false
                            this.showToast('Document eliminado correctament', 'CheckIcon', 'success')
                            this.getRequirement()
                        })
                        .catch(error => {
                            this.showLoading = false
                            this.showToast('Error en eliminar document', 'CheckIcon', 'Alert')
                            console.log(error)
                        })
                    }
                })
            } else {
                this.requirement_documents_model_190.splice(indexTax, 1)
            }

        },
        finishAllegation() {
            let textInvoice = ''

            Swal.fire({
                title: "Esteu segur que vols finalitzar l'al·legació?",
                text: textInvoice,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Sí, finalitzar!",
                cancelButtonText: "Cancel·lar",
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('requirements/finishAllegation', this.requirement.id)
                    .then(response => {
                        this.showLoading = false
                        this.getRequirement()
                        this.showToast('Al·legació finalitzada correctament', 'CheckIcon', 'success')
                    })
                    .catch(error => {
                        this.showLoading = false
                        this.showToast('Error a finalitzar al·legació', 'CheckIcon', 'Alert')
                        console.log(error)
                    })
                }
            })
        },
        sendMailInvoice() {
            this.$store.dispatch('requirements/finishAllegation', this.requirement.id)
            .then(response => {
                this.showLoading = false
                this.requirement.redo_allegation = 0
                this.showToast("S\'enviarà l'avís per a generar factura.", 'CheckIcon', 'success')
            })
            .catch(error => {
                console.log(error)
                this.showToast('Error en editar el requeriment!', 'AlertOctagonIcon', 'danger')
            })


        },
        selectRequirementReference(client) {
            this.$refs['modalRequirementsRelated'].show()
            this.$store.dispatch('requirements/getRequirementsByClientAndStatus', {
                client_id: client,
                status: 7, //status rechazado
                excludeRequirementWithReference: true,
            })
            .then(response => {
                this.showLoading = false
                if (response) {
                    this.requirementReferenceOptions = response
                }
            })
        },
        hideModal() {
            this.$refs['modalRequirementsRelated'].hide()
        },
        setRequirementReference(requirementReferenceId) {
            if (this.checkBoxReference) {
                this.requirement.requirement_reference_id = requirementReferenceId
            } else {
                this.requirement.requirement_reference_id = null
            }
        },
        addRequirementReference(closeModal = false) {
            this.$store.dispatch('requirements/getRequirementReference', {
                id: this.requirement.requirement_reference_id,
                relations: ['taxesTypes',
                    'documentsIVA', 'documentsIRPF', 'documentsIS', 'documentsCens', 'documentsModel347', 'documentsModel180', 'documentsModel190',
                    'documentsEmbargo'],
            })
            .then(response => {
                if (closeModal) {
                    this.$refs['modalRequirementsRelated'].hide()
                }

                this.requirement.taxes_types = response.taxes_types.map(function (tax) {
                    return tax['id']
                })

                this.requirement.person_in_charge = this.users.find(option => option.id == response.person_in_charge) ? this.users.find(option => option.id == response.person_in_charge).id : null


                this.requirement_documents_irpf = this.getUniqueListBy(this.requirement.requirement_documents_reference_irpf.concat(this.requirement_documents_irpf), 'id')
                this.requirement_documents_is = this.getUniqueListBy(this.requirement.requirement_documents_reference_is.concat(this.requirement_documents_is), 'id')
                this.requirement_documents_cens = this.getUniqueListBy(this.requirement.requirement_documents_reference_cens.concat(this.requirement_documents_cens), 'id')
                this.requirement_documents_model_347 = this.getUniqueListBy(this.requirement.requirement_documents_reference_model_347.concat(this.requirement_documents_model_347), 'id')
                this.requirement_documents_model_180 = this.getUniqueListBy(this.requirement.requirement_documents_reference_model_180.concat(this.requirement_documents_model_180), 'id')
                this.requirement_documents_model_190 = this.getUniqueListBy(this.requirement.requirement_documents_reference_model_190.concat(this.requirement_documents_model_190), 'id')
                this.requirement_documents_embargo = this.getUniqueListBy(this.requirement.requirement_documents_reference_embargo.concat(this.requirement_documents_embargo), 'id')
                this.showLoading = false
            }).catch(error => {
                this.showLoading = false
            })
        },
        getUniqueListBy(arr, key) {
            return [...new Map(arr.map(item => [item[key], item])).values()]
        },
        getUsers() {
            this.showLoading = true

            this.$store.dispatch('user/getAllUsers')
            .then(response => {
                this.users = response
                this.users_department = response
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-flatpicker.scss';

.heading-status {
    position: absolute;
    right: 10px;
    top: 17px;
}
</style>
<style scoped>
.input[aria-readonly] {
    background-color: cadetblue !important;
    border-radius: 3px;
    padding: 0 0.6em;
}

.selected-option-disabled {
    background-color: cadetblue !important;
    border-radius: 3px;
    padding: 0 0.6em;
}
</style>
